import { type ChainflipChain } from '@chainflip/utils/chainflip';
import { ChainflipLogo } from '@/shared/assets/chain-logos';
import { ChainflipTransparentLogo } from '@/shared/assets/chain-transparent-logos';
import { type ChainData, type ChainflipChainData } from '@/shared/assets/chains';
import { chainLogoMap, type ChainLogoVariant } from '@/shared/assets/chains/logo';
import { NotFound as ChainNotFound } from '@/shared/assets/not-found';
import { chainflipChainMap } from '@/shared/utils';

export const chainLogoDimensions = {
  xsmall: { width: 12, height: 12 },
  small: { width: 14, height: 14 },
  medium: { width: 16, height: 16 },
  large: { width: 24, height: 24 },
} as const;
export type ChainLogoSize = keyof typeof chainLogoDimensions;

export const DEFAULT_CHAIN_LOGO_SIZE: ChainLogoSize = 'small';
export const DEFAULT_CHAIN_LOGO_VARIANT: ChainLogoVariant = 'normal';

export const ChainLogo = ({
  chain,
  size = DEFAULT_CHAIN_LOGO_SIZE,
  variant = DEFAULT_CHAIN_LOGO_VARIANT,
  props = {},
}: {
  chain: ChainflipChain | ChainflipChainData | ChainData | 'Chainflip';
  size?: ChainLogoSize;
  /**
   * monochromatic/transparent chain logos sometimes deviate from the
   * chain logo dimensions defined in the design system. This prop allows for overriding that.
   * Not supposed to be used for normal logos.
   *
   */
  variant?: ChainLogoVariant;
  props?: React.SVGProps<SVGSVGElement>;
}) => {
  const { width, height } = chainLogoDimensions[size];

  // Chainflip asset
  if (typeof chain === 'string') {
    if (chain === 'Chainflip') {
      if (variant === 'transparent') {
        return <ChainflipTransparentLogo width={width} height={height} {...props} />;
      }

      return <ChainflipLogo width={width} height={height} {...props} />;
    }

    const chainflipChain = chainflipChainMap[chain];
    const Logo = chainLogoMap[chainflipChain.id][variant];
    return <Logo width={width} height={height} {...props} />;
  }

  const Logo = chainLogoMap[chain.id]?.[variant] ?? ChainNotFound;
  return <Logo width={width} height={height} {...props} />;
};
