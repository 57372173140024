import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { chainflipAssetMap, formatIpfsUrl, getChainflipToken } from '@/shared/utils';
import { NotFound as TokenNotFound } from '../../assets/not-found';
import { type ChainflipToken, type Token } from '../../assets/tokens';
import { tokenLogoMap } from '../../assets/tokens/logo';

export const tokenLogoDimensions = {
  small: { width: 18, height: 18 },
  medium: { width: 24, height: 24 },
  large: { width: 32, height: 32 },
} as const;
export type TokenLogoSize = keyof typeof tokenLogoDimensions;

export const DEFAULT_TOKEN_LOGO_SIZE: TokenLogoSize = 'small';

export const TokenLogo = ({
  token,
  size = DEFAULT_TOKEN_LOGO_SIZE,
  props,
}: {
  token: Token | ChainflipToken | ChainflipAsset;
  size?: TokenLogoSize;
  /**
   * in some cases, such as the pool asset combo, the designs require a different size than the
   * pre-defined token dimensions in the design system. This prop allows for overriding that.
   *  */
  props?: { width: number; height: number };
}) => {
  const { width, height } = tokenLogoDimensions[size];
  let Logo = TokenNotFound;
  let tokenLogo;

  // Chainflip assets
  if (typeof token === 'string') {
    const chainflipToken = getChainflipToken(token);
    Logo = tokenLogoMap[chainflipToken.symbol];
  } else if (token.chainflipId) {
    Logo = tokenLogoMap[chainflipAssetMap[token.chainflipId].symbol];
  }
  // External assets
  else if (typeof token.logo === 'string' && token.logo.length > 1) {
    tokenLogo = (
      <img
        className="rounded-[100%]"
        src={formatIpfsUrl(token.logo)}
        width={width}
        height={height}
        alt=""
        {...props}
      />
    );
  }

  return (
    <div style={{ height: `${props?.height ?? height}px`, width: `${props?.width ?? width}px` }}>
      {tokenLogo ?? <Logo width={width} height={height} {...props} />}
    </div>
  );
};
